import { useSelector, useDispatch } from "react-redux";
import { useVagaroAIContext } from '../../Store/VagaroAIContext';
import ReactDOM from "react-dom";
import { OpenDialog, OpenDialog_Type, utils } from '../../../utils/Utils';
import { setActivate } from '../../../component/Slice/BusinessSettings';
import { ThunkApiEnum,MobileCallBackAction } from '../../Binding/ChatBoatEnums';
import { ThunkAPI } from '../../../component/Common/AsyncThunk';
import TieredPricingComponent from "./TieredPricingComponent";

export const useBannerLogic = () => {
    const { TextMarketingEnable } = useSelector((state) => state.TextMarketing);
    const { Activate: show, UserAccess, currencySymbol, aiConnectPrice } = useSelector((state) => state.Settings);
    const { isAndroidIOSMobile, ModifyFeatureSubscription, initialData } = useVagaroAIContext();
    const dispatch = useDispatch();

    const updateSetting = (val) => {
        dispatch(setActivate(val));
        if (UserAccess && val) {
            ThunkAPI(dispatch, ThunkApiEnum.UpdateSetting)
                .then(() => {
                    utils.SuccessBanner("Vagaro AI Activated");
                })
                .catch(() => {});
        }
    };
  
     
    const HandleActiveAISettings = () => {
        if (!UserAccess) {
            utils.ShowAlert(
                "Restricted Access",
                "You have not been given access to this page. Contact your account admin or business owner to get access.",
                "Close"
            );
            return;
        }
        if (!ModifyFeatureSubscription) {
            utils.FailBanner("You do not have access to modify this page.");
            return;
        }
        if (TextMarketingEnable === null || TextMarketingEnable !== true) {
            utils.TextMarketingEnablePopup((isConfirmed) => {
                if (isConfirmed) {
                    HandleTextMarketingEnableeButton();
                }
            });
        } else {
            updateSetting(true);
        }
    };
    
    const handleGetStartedClick = () => {
        if (!UserAccess) {
            utils.ShowAlert(
                "Restricted Access",
                "You have not been given access to this page. Contact your account admin or business owner to get access.",
                "Close"
            );
        } else if (!ModifyFeatureSubscription) {
            utils.FailBanner("You do not have access to modify this page.");
        } else {
            const props = {
                currencySymbol: currencySymbol,
                aiConnectPrice: aiConnectPrice,
                show: show,
                onActivate: HandleActiveAISettings
            };
            // return ReactDOM.render(<TieredPricingComponent {...props} />, document.getElementById("OpenDialog"));
           return OpenDialog(
                OpenDialog_Type.OpenDialog,
                <TieredPricingComponent {...props} />,
                // document.getElementById("OpenDialog")
            );
            
        }
    };

    const OpenTextMessagingPlanPopup = (isEnable2WaySMS) => {
        if (initialData.TextMessagingModifyRights.toLowerCase() === "false") {
            utils.ShowAlert("Alert", "You Don't Have Rights!!", "Close");
            return;
        }
        const isMobile = isAndroidIOSMobile || /iPad|iPhone|Android/.test(navigator.userAgent);
        if (isMobile) {
            utils.CallBackGivenToMobile(MobileCallBackAction.OpenTextMessaging, true);
        } else {
            var jsonData = JSON.stringify({ isEnable2WaySMS: isEnable2WaySMS }).replace(/\\\"/g, '"').replace(/"/g, "\\\"");
            window.parent.postMessage('{"eventType": "OpenTextMessagingPlanActivePopup","Data": "' + jsonData + '"}', '*');
        }
    };

    const HandleTextMarketingEnableeButton = () => {
        OpenTextMessagingPlanPopup(false);
    };

    return {
        HandleActiveAISettings,
        handleGetStartedClick
    };
};
