import React, { Fragment } from 'react';
import { createPortal } from 'react-dom';
import VGPopUp from '../../../component/Common/VGPopUp';
import { CloseDialog, OpenDialog_Type, utils } from '../../../utils/Utils';
import { MobileCallBackAction } from '../../Binding/ChatBoatEnums';
const TieredPricingComponent = ({show, currencySymbol, aiConnectPrice ,onActivate,UserAccess ,isFromConnectApp}) => {
    const tieredPricingData = [
        { tier: 'Tier 1', locations: '1', price: '$10' },
        { tier: 'Tier 2', locations: '2-5', price: '$20', isActive: true },
        { tier: 'Tier 3', locations: '6-20', price: '$30' },
        { tier: 'Tier 4', locations: '21-100', price: '$50' },
        { tier: 'Tier 5', locations: '101-500', price: '$160' },
        { tier: 'Tier 6', locations: '501+', price: 'Contact Sales', isContact: true },
    ];

    const AlertClose = () => {
        // unmountComponentAtNode(document.getElementById("OpenDialog"));
        // utils.unmountNodeById("OpenDialog");
        CloseDialog(OpenDialog_Type.OpenDialog)
        setTimeout(() => {
            document.querySelector('.aiCancelBtn')?.classList.remove('clickEventNoneOndeActiveAi');
        }, 500);
    };

    const HandleButton = (activate) => {
        AlertClose();
        if (activate && onActivate) {
            onActivate(); // Call the callback function
        }
    };

    const onClickContactSales = () => {
        if (UserAccess === false) {
            utils.ShowAlert("Restricted Access", "You have not been given access to this page. Contact your account admin or business owner to get access.", "Close")
        } else {
            const RedirectUrl = 'https://www.vagaro.com/pro/contact-sales-team' ;
            if (isFromConnectApp) {
                utils.CallBackGivenToMobile(MobileCallBackAction.RedirectToLink, RedirectUrl);
            } else {
                window.parent.postMessage('{"eventType": "redirectToContactSalePage","Data": "' + RedirectUrl + '"}', '*');
            }
        }
    }
    const AlertTitle = () => {
        return (
            <Fragment>
                <Fragment>Vagaro AI Tiered Pricing</Fragment>
            </Fragment>
        );
    };

    const AlertBody = () => {
        return (
            <div className='multiLocationTieredModal'>
                <p className='multiLocationTieredModal-title'>
                    The pricing is calculated based on how many locations are included in your multi-location business.
                </p>
                <table className='multiLocationTieredModal-table'>
                    <thead>
                        <tr>
                            {/* <th>Tier</th> */}
                            <th>Number of Locations</th>
                            <th>Price ($/mo)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tieredPricingData.map((tier, index) => (
                            <tr key={index} className={tier.isActive ? 'activeTier' : ''}>
                                {/* <td>{tier.tier}</td> */}
                                <td>
                                    {tier.locations} {tier.isActive && <span className='activeTier-tag'>Current Tier</span>}
                                </td>
                                <td>
                                    {tier.isContact ? (
                                        <a href={false} className='contactSalesUrl' onClick={() => onClickContactSales()}>
                                            {tier.price}
                                        </a>
                                    ) : (
                                        tier.price
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const AlertFooter = () => {
        return (
            <Fragment>
                <Fragment>
                    <button
                        className="btn btn-secondary vcgradientbtn vcsecondary-btn"
                        onClick={() => AlertClose()}
                    >
                        <div className="vcsecondaryInner">Cancel</div>
                    </button>
                    {!show && (
                    <button
                        className="btn btn-primary vcgradientbtn vcprimary-btn"
                        onClick={() => HandleButton(true)}// Pass true to indicate activation
                    >
                        Activate - {currencySymbol}{aiConnectPrice}/mo
                    </button>
                    )}
                </Fragment>
            </Fragment>
        );
    };

    return createPortal(
        <VGPopUp
            id={"Alert"}
            show={true}
            onHide={() => { AlertClose(); }}
            title={AlertTitle()}
            dialogClassName="vgn-modal-alert vgn-modal-small modal-mb-half tieredPricingModal"
            body={AlertBody()}
            footer={AlertFooter()}
            isRemoveTabModalIndex={false}
            isShowFooter={true}
            backdrop={true}
        />,
        document.getElementById("OpenDialog")
    );
};

export default TieredPricingComponent;