import React, { Fragment, useEffect, useRef, useState } from 'react';
import { BlobServiceClient } from '@azure/storage-blob';
import { useChatContext } from '../Store/ChatProvider';
import { AsyncAjaxGet } from '../../component/Common/AsyncAjaxServiceV2';
import { OpenDialog, OpenDialog_Type, utils } from '../../utils/Utils';
import ReactDOM from 'react-dom';
import SuccessAlert from '../../component/Common/SuccessAlert';
import { CommonMethods, FileStatus } from '../Binding/ChatBoatEnums';
import VideoPlayer from './VideoPlayer';



export const FileUpload = ({ Type, Editor, MassageDetail, inputFile, InputPhotoLibrary, InputCamera, merchantId, HandleImage, FileUploadProgress, FileUplodStatus, EditorOnChange, isFromConnectApp, isIOSMobile, isCustomerChat }) => {
  const version = utils.extractConnectVersion()
  const { chatSelectionID, chatSelection, AddCommonMethod } = useChatContext();
  const { EventCall, updateEvent } = utils;
  const VideoContainerRef = useRef(false);
  const UplodingRef = useRef(false);
  // { id: 0, Status: "Loding", Url: "", Type: "img", File: null }
  const [Files, setFile] = useState([]);
  const FilesRef = useRef([]);
  const FilesFirstLoadRef = useRef(null);
  const [connectionString, setConnectionString] = useState("");
  const [containerName, setContainerName] = useState("");
  const Video_content = document.getElementById(`Video-content${Type}`);
  const ckeditor_ = document.getElementsByClassName(`ckeditor_${Type}`)[0];
  const RemoveFileFlag = useRef(false);
  const VagaroAPIEnum = {
    GetAzureTokenURl: `connect/${merchantId}/containersastoken?ConversationId=${chatSelection.ConversationId ? chatSelection.ConversationId : ""}&ExpirySecond=600`,
  };
  const getRandomID = () => {
    return Math.floor(Math.random() * 99999);
  }
  const handleFileChange = (event) => {
    var ImageEvent = event;
    const ImageDataTransfer = new DataTransfer();
    const videoDataTransfer = new DataTransfer();
    Object.values(event.target.files).map((file) => {
      if (file.type.includes("image")) {
        ImageDataTransfer.items.add(file)
      } else if (file.type.includes("video") && ["video/3gpp2", "video/3gpp", "video/3gpp", "video/x-m4v", "video/x-matroska", "video/quicktime", "video/mp4", "video/webm"].includes(file.type)) {
        videoDataTransfer.items.add(file)
      }
    })
    ImageEvent.target.files = ImageDataTransfer.files;
    if (ImageEvent.target.files.length > 0) {
      HandleImage(ImageEvent)
    }
    if (videoDataTransfer.files.length > 0) {
      HandleUplod(videoDataTransfer.files)
    }
  };
  const HandleUplod = (Files) => {
    var TotalFiles = Files.length;
    const FileLenghth = FilesRef.current.length;
    const TotalLength = FileLenghth + TotalFiles;
    if (TotalFiles > 5 || TotalLength > 5) {
      return OpenDialog(
        OpenDialog_Type.OpenDialog1,
        <Fragment>
          <SuccessAlert
            alertType={"warning"}
            strMessage={"Please select up to 5 files."}
            displaytime={2000}
          />
        </Fragment>,
        // document.getElementById("OpenDialog1")
      );
    } else {
      let maxSizeInBytes = 500 * 1024 * 1024;
      if (Files) {
        Object.values(Files).map((file) => {
          if (file && file.size > maxSizeInBytes) {
            OpenDialog(
              OpenDialog_Type.OpenDialog1,
              <Fragment>
                <SuccessAlert
                  alertType={"warning"}
                  strMessage={"File size limit exceeds 500 MB."}
                  displaytime={3000}
                />
              </Fragment>,
              // document.getElementById("OpenDialog1")
            );
          } else {
            setFile((prev) => [...prev, { id: getRandomID(), Status: FileStatus.Idle, Url: "", Type: file.type, FileName: file.name, File: file }])
          }
          return true;
        })
      }
    }
  };
  const HandlePendingUplod = () => {
    const PendingFiles = Files.filter(x => x.Status === FileStatus.Loding || x.Status === FileStatus.Failed)
    for (let index = 0; index < Files.length; index++) {
      const File = Files[index];
      if ((File.Status === FileStatus.Idle || File.Status === FileStatus.Loding) && !UplodingRef.current) {
        UplodingRef.current = true;
        const abortController = new AbortController();
        setFile(UpdateStatus => UpdateStatus.map(prev => prev.Status === FileStatus.Idle ? { ...prev, Status: FileStatus.Loding, CancelToken: abortController } : { ...prev }));
        uploadFile(File, abortController.signal)
      }
    };
    if (PendingFiles.length === 0) {
      FileUploadProgress(false);
    } else {
      if (isCustomerChat && Type === 1) {
        CommonMethods.AddClass(".typeChatWrap.collapsibleChat", "mobileActiveChat");
      }
    };
  };
  const RemoveFile = (removeID) => {
    FilesRef.current.map((arr) => {
      if (arr.id === removeID && arr.CancelToken) {
        arr.CancelToken.abort();
      }
      return true;
    })
    RemoveFileFlag.current = true;
    setFile(UpdateArr => UpdateArr.filter(prev => prev.id !== removeID));
  };
  const RetryUplod = (ID) => {
    setFile(UpdateArr => UpdateArr.map(prev => prev.id === ID ? { ...prev, Status: FileStatus.Idle } : { ...prev }));
  };
  const uploadFile = async (Arr, CancelToken) => {
    if (!Arr.File) return;
    if (connectionString === "") return;
    const selectedFile = Arr.File;
    const FileName = selectedFile.name.split(" ").join("")
    const blobServiceClient = new BlobServiceClient(connectionString);
    const containerClient = blobServiceClient.getContainerClient(containerName);
    const blockBlobClient = containerClient.getBlockBlobClient(encodeURIComponent(FileName));
    var FileUrl = ""

    try {
      await blockBlobClient.uploadBrowserData(selectedFile, {
        blobHTTPHeaders: { blobContentType: selectedFile.type },
        abortSignal: CancelToken
      });
      UplodingRef.current = false;
      FileUrl = blockBlobClient.url.split("?")[0];
      setFile(prevArr => prevArr.map(prev => prev.id === Arr.id ? { ...prev, Status: FileStatus.Done, Url: FileUrl, Type: selectedFile.type, File: null, CancelToken: null } : { ...prev }));
      console.log("FileURL", blockBlobClient.url.split("?")[0]);
    } catch (error) {
      UplodingRef.current = false;
      if (error.name === 'AbortError') {
        console.log('Upload canceled');
      } else {
        setFile(prevArr => prevArr.map(prev => prev.id === Arr.id ? { ...prev, Status: FileStatus.Failed, Type: selectedFile.type, CancelToken: null } : { ...prev }));
        GetAzureConnection();
        console.error('Error uploading file:', error);
      }
    }
  };
  const GetAzureConnection = async () => {
    const access_token = "";
    await AsyncAjaxGet(
      VagaroAPIEnum.GetAzureTokenURl,
      access_token,
      function onSuccess(data) {
        try {
          if (data != null && data.data != null && data.data.data != null) {
            setConnectionString(data.data.data.azureUrl);
            setContainerName(data.data.data.folderPath);
          }
          else {
            utils.appInsightTrack(
              "props.ReactBusinessId",
              "props.ReactUserID",
              "Vagaro Links",
              "",
              data != null && data.data != null ? data.data : ""
            );
          }
        } catch (error) {
          utils.appInsightTrack(
            "props.ReactBusinessId",
            "props.ReactUserID",
            "Vagaro Links",
            "",
            error
          );
        }
      },
      function OnError(data) {
        utils.appInsightTrack(
          "props.ReactBusinessId",
          "props.ReactUserID",
          "Vagaro Links",
          "",
          data
        );
      },
      true
    );
  };

  useEffect(() => {
    FilesRef.current = Files;
    HandlePendingUplod();
    FileUplodStatus(FilesRef.current.filter(x => x.Status !== FileStatus.Done).length);
    setTimeout(() => { EventCall(`EditorFileChages${Type}`, Files) }, 10);
    if (FilesRef.current !== undefined && FilesRef.current !== null && FilesRef.current !== "" && FilesRef.current?.length > 0) {
      EditorOnChange();
    }
    if (FilesRef.current.filter(x => x.Status === FileStatus.Done).length > 0) {
      utils.EventCall("AttachmentLocalStorageForConversation", (update) => {
        update && update()
      })
      if (isCustomerChat && Type === 1) {
        CommonMethods.AddClass(".typeChatWrap.collapsibleChat", "mobileActiveChat");
      }
    } else if (RemoveFileFlag.current && FilesRef.current.length === 0) {
      RemoveFileFlag.current = false;
      utils.EventCall("AttachmentLocalStorageForConversation", (update) => {
        update && update()
      })
      if (isCustomerChat && Type === 1) {
        CommonMethods.RemoveClass(".typeChatWrap.collapsibleChat", "mobileActiveChat");
      }
    }
    if (Video_content) {
      if (Files.length > 0) {
        Video_content.style.display = 'block';
        ckeditor_ && ckeditor_.classList.add("videoAttached")
        FilesFirstLoadRef.current = true;
      } else {
        Video_content.style.display = 'none';
        ckeditor_ && ckeditor_.classList.remove("videoAttached")
      }

    };
    if (FilesFirstLoadRef.current === true) {
      Editor.current.editing.view.focus();
    }
  }, [Files]);

  useEffect(() => {
    GetAzureConnection();
    setFile([]);
    VideoContainerRef.current = false;
    AddCommonMethod("ClearVideoContent", () => {
      FilesRef.current = [];
      setFile([]);
      utils.EventCall("AttachmentLocalStorageForConversation", (update) => {
        update && update()
      })
    })
    updateEvent(`EditorFileRemove${Type}`, (id) => {
      if (id) {
        RemoveFile(id)
      }
    });
    updateEvent(`EditorFileRetry${Type}`, (id) => {
      if (id) {
        RetryUplod(id)
      }
    });
    updateEvent(`DropFileInInput${Type}`, (DataTransfer) => {
      if (DataTransfer && DataTransfer.files.length > 0) {
        HandleUplod(DataTransfer.files)
      }
    });
    updateEvent(`FileUplodStatus${Type}`, (status) => {
      if (FilesRef.current.length) {
        status(FilesRef.current.filter(x => x.Status !== FileStatus.Done).length)
      } else {
        status(0)
      }
    });
    updateEvent(`EditorFileChagesPersist${Type}`, (Callback) => {
      if (FilesRef.current.length) {
        Callback(FilesRef.current.filter(x => x.Status === FileStatus.Done))
      } else {
        Callback([])
      }
    });
    updateEvent(`UpdatePersistFile${Type}`, (PersistFile) => {
      if (PersistFile.length > 0) {
        setFile(PersistFile);
      }
    });
    updateEvent(`TotalAttachments${Type}`, (status) => {
      if (FilesRef.current.length) {
        status(FilesRef.current.length)
      } else {
        status(0)
      }
    });

  }, [chatSelectionID]);

  useEffect(() => {
    if (MassageDetail && MassageDetail.attachment.length > 0) {
      const MassageAttachment = [];
      for (let i = 0; i < MassageDetail.attachment.length; i++) {
        const element = MassageDetail.attachment[i];
        if (element.type !== "" && element.type !== undefined && element.type !== null) {
          MassageAttachment.push({ id: getRandomID(), Status: FileStatus.Done, Url: element.fileUrl, Type: element.type, FileName: element.fileName, File: null })
        }
      }
      setFile(MassageAttachment);
    }
  }, [MassageDetail])

  useEffect(() => {
    window.addEventListener("offline", () => {
      FilesRef.current.map((arr) => {
        if (arr.CancelToken) {
          arr.CancelToken.abort();
        }
        return true;
      });
      setFile(UpdateArr => UpdateArr.filter(x => x.Status === FileStatus.Done));
    });
    return () => {
      window.removeEventListener("offline", () => { console.log("removehello"); });
    };
  }, []);
  const acceptAsVersion = () => {
    if (version && isFromConnectApp === true) {
      let Version = utils.compareVersions(version, isIOSMobile ? "1.0.9" : "1.0.7");
      if (Version <= 0) {
        return "image/jpeg,image/png,image/gif,image/bmp,image/webp";
      }
      return "image/jpeg,image/png,image/gif,image/bmp,image/webp,video/mp4,video/webm,video/quicktime,video/x-matroska,video/3gpp2,video/3gpp,video/x-ms-asf,video/x-m4v";
    }
    return "image/jpeg,image/png,image/gif,image/bmp,image/webp,video/mp4,video/webm,video/quicktime,video/x-matroska,video/3gpp2,video/3gpp,video/x-ms-asf,video/x-m4v";
  };

  return (
    <div>
      <input id='inputCamera' ref={InputCamera} onClick={(e) => { e.target.value = "" }} onChange={handleFileChange} style={{ display: "none" }} type="file" capture="camera" accept={acceptAsVersion()} multiple ></input>
      <input id={inputFile} ref={InputPhotoLibrary} onClick={(e) => { e.target.value = "" }} type="file" accept={acceptAsVersion()} style={{ display: "none" }} onChange={handleFileChange} multiple />
    </div>
  );
};



export const VideoContent = ({ type, CustomerChat, Connectapp, isIOSMobile, isAndroidIOSMobile }) => {
  const [Videos, setVideos] = useState([]);
  const { expandMinimizeSize, updateEvent, EventCall } = utils;
  const RemoveVideo = (id) => {
    EventCall(`EditorFileRemove${type}`, id)
  };
  const RetryVideo = (id) => {
    EventCall(`EditorFileRetry${type}`, id)
  };
  useEffect(() => {
    setVideos([])
    updateEvent(`EditorFileChages${type}`, (data) => {
      if (data) {
        setVideos(data)
      }
    })
  }, [])
  return (<div className={Videos.length > 1 ? Videos.length > 3 ? 'videoWrapInmessage videoMainwrap uploadMultipalVideo moreThenthree' : 'videoWrapInmessage videoMainwrap uploadMultipalVideo' : 'videoWrapInmessage videoMainwrap'} data-Video={Videos.length} >
    {Videos.map(({ id, Status, Url, FileName, Type }) => (
      <>
        <div className='videoPlayer'>
          <div className={Status === "Loading" ? "Loaded " : "Loading"} >
            {Status === FileStatus.Done ?
              <div className='videoPlayerBlock'>

                {Type.includes("video") ?
                  <VideoPlayer
                    key={id}
                    URL={Url}
                    Preview={Videos.length === 1 ? true : false}
                    Status={Status}
                    FileName={FileName}
                    Type={Type}
                    Connectapp={Connectapp}
                    isIOSMobile={isIOSMobile}
                    isAndroidIOSMobile={isAndroidIOSMobile}
                  />
                  :
                  <img width={163} alt={FileName} src={Url}></img>
                }
              </div>
              :
              <>
                {Videos.length > 3 ?
                  <></>
                  :
                  Status === FileStatus.Failed ?
                    <div className='loadingWrap' onClick={() => RetryVideo(id)}>
                      {/* <i class="fa fa-repeat" ></i> */}
                      <span className='loaderText'>Upload Failed</span>
                      <span className='loaderText'>
                        <i class="fa-light fa-rotate-right"></i>
                      </span>
                    </div>
                    :
                    <div className='loadingWrap'>
                      <span className='videoloader'></span>
                      <span className='loaderText'>Loading...</span>
                    </div>
                }
              </>
            }
            <div className='video-footer'>
              <span className='videoFileName'>
                <span className='iconLoader'>
                  {((Videos.length > 3 || CustomerChat || Connectapp || expandMinimizeSize === 2) && Status !== FileStatus.Done) ?
                    Status === FileStatus.Failed ?
                      <span class="loadingWrap" onClick={() => RetryVideo(id)}><i class="fa-light fa-rotate-right" aria-hidden="true"></i></span>
                      :
                      <span class="loadingWrap"><span class="videoloader"></span></span>
                    :
                    <i className='fa-sharp fa-regular fa-circle-play'></i>
                  }
                </span>
                <span className='videoFileNameBlock'>
                  {FileName}
                </span>
              </span>
              <button onClick={() => { RemoveVideo(id) }} className='videoClose' >
                <i class="fa-light fa-xmark"></i>
              </button>
            </div>
          </div>
        </div>
      </>
    ))}
  </div>
  )
} 