import React, { Fragment, useEffect, useRef, useState } from "react";
import $ from 'jquery';
import { AsyncAjaxGet } from "../Common/AsyncAjaxServiceV2";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/VagaroStyle/select2.scss";
import ReactDom from "react-dom";
import useGenerateToken from "../Hooks/useGenerateToken";
import "../../utils/select2_4.0.js"
import { useChatContext } from "../../Vagaroconnect/Store/ChatProvider.jsx";
import { utils } from "../../utils/Utils";

const MerchantsSingleSelecttwo = (props) => {
  const { chatSelectionID, AddCommonMethod } = useChatContext();
  const firstLoad = useRef(false);

  const dispatch = useDispatch();
  const { access_token } = useSelector((state) => state.Token);
  const [isandroidiospro, setisandroidiospro] = useState(props.isandroidiospro ? props.isandroidiospro : false);
  const [ControlName, setControlName] = useState("")
  const cache_custlist = "customerSync";
  let orientationQuery = window.matchMedia('(orientation: portrait)');
  const cachingDataInfo = { pageSize: isandroidiospro ? orientationQuery.matches ? 30 : 20 : 10, cache_isSyncStarted: false, };
  let isIpad = /Macintosh/i.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;
  let isIpadold = /iPad/i.test(navigator.userAgent);
  let isTablet = /VagaroAndroidTablet/i.test(navigator.userAgent);
  const Control = {
    ControlID: "#" + props.ControlID
  }
  var customerDetails = [];
  const isFromLoacalStorage = () => {
    let customerFromLocalStorage = JSON.parse(localStorage.getItem('customerSync'));
    if (customerFromLocalStorage !== undefined && customerFromLocalStorage !== null && customerFromLocalStorage.length > 0) {
      return true;
    } else {
      return false;
    }
  }
  const getCustommerList = () => {
    let customerFromLocalStorage = JSON.parse(localStorage.getItem('customerSync'));
    if (customerFromLocalStorage !== undefined && customerFromLocalStorage !== null && customerFromLocalStorage.length > 0) {
      return customerFromLocalStorage;
    } else {
      return [];
    }
  }
  const OnChangeCustomer = (id, data, selectedData) => {
    props.OnChangeCustomer(id, data.dataset, selectedData);
  }
  const Sortingalphabetical = (a, b) => {
    try {
      var A = a.fullName.toLowerCase();
      var B = b.fullName.toLowerCase();
      if (A < B) {
        return -1;
      } else if (A > B) {
        return 1;
      } else {
        return 0;
      }
    } catch (et) {
      return 0;
    }
  };

  const replaceNullOrUndefined = (val) => {
    return val == null || val === undefined ? "" : val;
  };

  const SearchCustomer = (customerStore, customerDateLength, searchedText) => {
    let lengthOfCustomer = 0;
    let filteredCustomers;
    let textTrimed
    if (customerStore !== null && customerStore.length > 0) {

      // Text Trim.
      if (searchedText !== undefined) {

        textTrimed = searchedText;

        if (textTrimed.length > 0) {
          textTrimed = textTrimed.replace(/\(/g, "").replace(/\)/g, "").replace(/-/g, "").trim();
        }
      }

      lengthOfCustomer = customerDateLength;

      if (isFromLoacalStorage()) {
        filteredCustomers = $.grep(customerStore, function (item) {
          return (textTrimed === "" || smartSaerch(textTrimed.toLowerCase(), item.fullName.trim().toLowerCase()));
        });

        return filteredCustomers;
      }

      return customerStore
    } else {
      return [];
    }


  }
  const smartSaerch = (term, text) => {
    var spaceReplaceRegex = /\s\s+/g;
    var Regex = /[^0-9a-zA-Z\" \"]+/gi;

    var FilterSearch = term.replace(spaceReplaceRegex, " ");
    FilterSearch = FilterSearch.toLowerCase().replace(Regex, "");

    var arrsSearchKeyword = FilterSearch.split(" ");
    var matchedArray = "";
    var reqmatchCount = arrsSearchKeyword.length;
    var totMatchFound = 0;
    for (var i = 0; i <= arrsSearchKeyword.length - 1; i++) {
      var TextSearch = text.replace(spaceReplaceRegex, " ");
      TextSearch = TextSearch.toLowerCase().replace(Regex, "");
      if (TextSearch.match("^" + arrsSearchKeyword[i]) || TextSearch.indexOf(" " + arrsSearchKeyword[i]) != -1) {
        if (
          matchedArray == "" ||
          arrsSearchKeyword[i].indexOf(matchedArray) < 0
        ) {
          totMatchFound += 1;
        }
        matchedArray = " " + TextSearch;
        if (totMatchFound >= reqmatchCount) {
          break;
        }
      }
    }
    if (totMatchFound >= reqmatchCount) {
      return true;
    } else {
      return false;
    }
  }

  const GetCutomnerData = (searchText, objUsersList, totalCount) => {
    if (totalCount == null) {
      var CustomerData =
        typeof localStorage === "object" &&
          localStorage[cache_custlist] !== undefined &&
          localStorage[cache_custlist].length > 0
          ? JSON.parse(localStorage[cache_custlist] || [])
          : [];
      if (replaceNullOrUndefined(CustomerData) && CustomerData != "") {
        try {
          CustomerData = CustomerData.sort(Sortingalphabetical);
        } catch (g) {
          console.log(g);
        }
        return SearchCustomer(CustomerData, CustomerData.length, searchText);
      }
    } else {
      return SearchCustomer(objUsersList, totalCount, searchText);
    }
  };

  const ApplySinglSelect = () => {
    if ($(Control.ControlID).data('select2')) {
      $(Control.ControlID).select2("destroy");
    }
    $(Control.ControlID).select2({
      placeholder: props.placeholder,
      searchInputPlaceholder: isandroidiospro ? "Search" : "",
      allowClear: true,

      closeOnSelect: true,
      width: "element",
      language: {
        noResults: function () {
          return isandroidiospro ? "No results found" : ""
        }
      },
      templateResult: function (obj) {
        if (!obj.id) {
          return obj.text;
        }
        var $obj = $(
            '<label data-id="' + obj.id + '"firstName="' + obj.firstName + '"data-lastName="' + obj.lastName + '">' + obj.text + "</label>"
          );
        
        return $obj;
      },
      ajax: {
        data: function (params) {
          return {
            q: params.term,
            page: params.page || 1,
          };
        },
        results: function (data) {
          return { results: data };
        },
        transport: function (params, success, failure) {
          try {
            if (isFromLoacalStorage() === true) {
              var SearchData = GetCutomnerData(params.data.q || "", null, null);
              var data = SearchData.map(function (item) {
                return {
                  id: item.userId,
                  text: item.fullName,
                };
              });
              success({
                results: data.slice(
                  (params.data.page - 1) * cachingDataInfo.pageSize,
                  params.data.page * cachingDataInfo.pageSize
                ),
                pagination: {
                  more:
                    data.length >= params.data.page * cachingDataInfo.pageSize,
                },
              });
              updateCount(SearchData.length); failure({});
            } else {
              Cache_DatabaseCall(
                params.data.q || "",
                params.data.page,
                cachingDataInfo.pageSize,
                false,
                function (response) {
                  var SearchData = GetCutomnerData(
                    params.data.q || "",
                    response.customer,
                    response.totalCount
                  );
                  var data = SearchData.map(function (item) {
                    return {
                      id: item.userId,
                      text: props.ManageCustomerViewRights ? item.cell !== undefined && item.cell !== null && item.cell !== "" ? item.fullName + " - " + item.cell : item.fullName : item.fullName,
                      firstName: item.firstName,
                      lastName: item.lastName
                    };
                  });
                  success({
                    results: data,
                    pagination: {
                      more:
                        params.data.page * cachingDataInfo.pageSize <
                        response.totalCount,
                    },
                  });
                  for (let index = 0; index < data.length; index++) {
                    const element = data[index];
                    $("#" + props.ControlID).append(`<option id="${element.id}" data-value="${element.id}" data-firstName="${element.firstName}"  data-lastName="${element.lastName}">${(element.text)}</option>`);
                  }
                  updateCount(response.totalCount); failure({});
                },
                function (response) { }
              );
            }
          } catch (a) {
            console.log(a);
          }
        },
      },
    });

  }

  const Cache_DatabaseCall = async (
    strText,
    pageIndex,
    pagesize,
    IsForLocalStorage,
    OnSuccess,
    OnFail
  ) => {
    const accesstoken = await useGenerateToken(dispatch);
    await AsyncAjaxGet(
      `${props.MerchantID}/VagaroConnect/customers?PageIndex=${pageIndex}&PageSize=${pagesize}&searchtext=${strText}`,
      accesstoken,
      function onSuccess(data) {
        try {
          if (data != null && data.data != null && data.data.data != null) {
            customerDetails = customerDetails.concat(data.data.data.customer);
                        OnSuccess(data.data.data)
          }
        } catch (e) {
          utils.appInsightTrack(
            props.ReactBusinessId,
            props.ReactUserID,
            "Customer List",
            "",
            e
          );
        }
      },
      function OnError(error) {
        OnFail(error);
        utils.appInsightTrack(
          props.ReactBusinessId,
          props.ReactUserID,
          "Customer List",
          "",
          error
        );
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      $(Control.ControlID).trigger("click");
    }, 3000);
  }, [props.ControlID]);

  useEffect(() => {
    AddCommonMethod("SelectCustommerBYHasID", (ID) => {
      var User = customerDetails.find((arr) => arr.userIdHash === ID);
      if (User && User.userId) {
        $('#Customer').val([User.userId]).trigger('change');
      }
    })
  }, [chatSelectionID]);


  // const GetCustomerList = async () => {   
  //   const accesstoken = await useGenerateToken(dispatch);
  //   // if (LeftControlNames.Customer === props.isLeftOption && props.ViewCustomer === false) {
  //   //   utils.ShowAlert("Alert", "You Don't Have Rights!!", "Close");
  //   // }
  //   // else {
  //   if (isFromLoacalStorage() === false) {

  //     await AsyncAjaxGet(
  //       `${props.MerchantID}/VagaroConnect/customers?PageIndex=1&PageSize=20`,
  //       accesstoken,
  //       function onSuccess(data) {
  //         try {
  //           if (data != null && data.data != null && data.data.data != null) {


  //           }
  //         } catch (e) { console.log(e); }
  //       },
  //       function OnError(error) {

  //       }
  //     );
  //     // }
  //   }
  // }
  const updateCount = (Count) => {
    if ($('.load-more').is(':visible')) {
      ReactDom.render(
        <span>Items <b>1 - {$('.select2-results__options').find('li').length - 1}</b>  out of <b>{Count}</b> </span>,
        document.getElementById("div-select2-results-count")
      )
    }
    else if (Count === 0) {
      ReactDom.render(
        <span>No Matches</span>,
        document.getElementById("div-select2-results-count")
      )
    }
    else {
      ReactDom.render(
        <span>Items <b>1 - {$('.select2-results__options').find('li').length}</b> out of <b>{$('.select2-results__options').find('li').length}</b></span>,
        document.getElementById("div-select2-results-count")
      )
    }
  }

  useEffect(() => {
    if (isandroidiospro) {
      ApplySinglSelect();
      $(Control.ControlID).on(
        "select2:open",
        function (e) {
          if (isIpad || isIpadold || isTablet || (navigator.userAgent.indexOf("Android") > -1 && navigator.userAgent.indexOf("Mobile") > -1) || navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
            $('.select2-search__field').prop('focus', false);
            $('.select2-container').addClass("employee-multilocation-select");
            $('.select2').removeClass("employee-multilocation-select");
          } else {
            $(".select2-search__field").attr("enterkeyhint", "Go");
            $('.select2-search__field').prop('focus');
          }
          $('.select2-search__field').on('dragover drop', function (e) {
            e.preventDefault(); // Prevent the default behavior of dragover and drop events
          });
          $('.select2-container').addClass(props.MultiCustomersId);
          $('.select2-container').addClass("driveFormMselect");
          $('.select2-container').addClass("vcEmployeeSelect");
          if (!$(".select2backdrop").is(":visible")) {
            $("body").append('<div class="select2backdrop ' + props.MultiCustomersId + '"></div>');
          }
          $('.select2backdrop').on('click', function () {
            $(Control.ControlID).select2("close");
            if (isandroidiospro) {
              $(Control.ControlID).select2('destroy')
            }
          });
          if (!$("#div-select2-results-count").is(":visible")) {
            $('.select2-results').append('<div id="div-select2-results-count" class="select2-results-count"></div>');
          }
          if ($('#CancelOnCLick').length === 0) {
            isandroidiospro === true && $('.select2-search').append('<button id="CancelOnCLick" class="mobileCancelSelect2">Cancel</button>');
          }
          $('.select2-search').on('click', '#CancelOnCLick', function () {
            CancelOnCLick();
          });
          $('.select2-search__field').on('focus', function () {
            var isFocused = $('.select2-search__field').is(':focus');
            window.InputElemntsFocued = true;
            if (isFocused) {
              $('.select2-search--dropdown').addClass("selectSearchActive");
            }
          });

          $('.select2-search__field').on('blur', function () {
            const select2Input = $('.select2-search__field');
            window.InputElemntsFocued = false;
            if (select2Input.val() === undefined || select2Input.val() === null || select2Input.val() === "") {
              $('.select2-search--dropdown').removeClass("selectSearchActive");
            }
          });
        });
      $(Control.ControlID).on('select2:close', function (e) {
        $('.select2backdrop ').hide().remove();
        $('.select2-container').removeClass("driveFormMselect");
      });
      $(Control.ControlID).on('select2:closing', function (e) {
        $(".select2-dropdown--below").removeClass("width100");
        $(".select2-results").css("visibility", "hidden");
      });
      $(Control.ControlID).on('select2:opening', function (e) {
        setTimeout(function () {
          $(".select2-dropdown--below").addClass("width100");
          $(".select2-results").css("visibility", "visible");
        }, 5);
      });
      $(Control.ControlID).on("change", function () {
        // OnChangeCustomer(customerDetails.filter((cust) => cust.userId === this.value).length > 0 ? customerDetails.filter((cust) => cust.userId === this.value)[0] : this.value);    
        let data = {}
        for (let index = 0; index < this.length; index++) {
          if (this.value == this[index].dataset.value) {
            data.dataset = this[index].dataset;
          }
        }
        var selectedValues = customerDetails.filter((cust) => cust.userId === this.value).length > 0 ? customerDetails.filter((cust) => cust.userId === this.value)[0] : this.value;
        OnChangeCustomer(this.value, data, selectedValues);
      });
      setTimeout(() => {
        $(Control.ControlID).trigger("select2:open");
        if (isIpad || isandroidiospro || isIpadold || isTablet) {
          $(Control.ControlID).select2("open");
        }
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (!isandroidiospro && !isIpad && !isIpadold && !isTablet) {
      ApplySinglSelect();
      $(Control.ControlID).on(
        "select2:open",
        function (e) {
          if ((navigator.userAgent.indexOf("Android") > -1 && navigator.userAgent.indexOf("Mobile") > -1) || navigator.userAgent.indexOf("VagaroAndroidPhone") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
            $('.select2-search__field').prop('focus', false);
            $('.select2-container').addClass("employee-multilocation-select");
            $('.select2').removeClass("employee-multilocation-select");
          } else {
            $(".select2-search__field").attr("enterkeyhint", "Go");
            $('.select2-search__field').prop('focus');
          }
          $('.select2-search__field').on('dragover drop', function (e) {
            e.preventDefault(); // Prevent the default behavior of dragover and drop events
          });
          $('.select2-container').addClass(props.MultiCustomersId);
          $('.select2-container').addClass("driveFormMselect");
          $('.select2-container').addClass("vcEmployeeSelect");
          $("body").append('<div class="select2backdrop ' + props.MultiCustomersId + '"></div>');
          $('.select2backdrop').on('click', function () {
            $(Control.ControlID).select2("close");
            if (isandroidiospro) {
              $(Control.ControlID).select2('destroy')
            }
          });
          if (!$("#div-select2-results-count").is(":visible")) {
            $('.select2-results').append('<div id="div-select2-results-count" class="select2-results-count"></div>');
          }
        });
      $(Control.ControlID).on('select2:close', function (e) {
        $('.select2backdrop ').hide().remove();
        $('.select2-container').removeClass("driveFormMselect");
      });
      $(Control.ControlID).on('select2:closing', function (e) {
        $(".select2-dropdown--below").removeClass("width100");
        $(".select2-results").css("visibility", "hidden");
      });
      $(Control.ControlID).on('select2:opening', function (e) {
        setTimeout(function () {
          $(".select2-dropdown--below").addClass("width100");
          $(".select2-results").css("visibility", "visible");
        }, 5);
      });
      $(Control.ControlID).on("change", function () {
        // OnChangeCustomer(customerDetails.filter((cust) => cust.userId === this.value).length > 0 ? customerDetails.filter((cust) => cust.userId === this.value)[0] : this.value);    
        let data = {}
        for (let index = 0; index < this.length; index++) {
          if (this.value == this[index].dataset.value) {
            data.dataset = this[index].dataset;
          }
        }
        var selectedValues = customerDetails.filter((cust) => cust.userId === this.value).length > 0 ? customerDetails.filter((cust) => cust.userId === this.value)[0] : this.value;
        OnChangeCustomer(this.value, data, selectedValues);
      });
      setTimeout(() => {
        $(Control.ControlID).trigger("select2:open");
        if (isandroidiospro) {
          $(Control.ControlID) && $(Control.ControlID).select2("open");
        } else {
          if (!firstLoad.current) {
            $(Control.ControlID) && $(Control.ControlID).select2("open");
            firstLoad.current = true;
          }
        }
      }, 500);
    }
    if ((isTablet || isIpad || isIpadold) && ControlName !== props.ControlID && orientationQuery.matches === false) {
      ApplySinglSelect();
      $(Control.ControlID).on(
        "select2:open",
        function (e) {
          $('.select2-search__field').prop('focus', false);
          $('.select2-container').addClass("employee-multilocation-select");
          $('.select2').removeClass("employee-multilocation-select");

          $('.select2-search__field').on('dragover drop', function (e) {
            e.preventDefault(); // Prevent the default behavior of dragover and drop events
          });
          $('.select2-container').addClass(props.MultiCustomersId);
          $('.select2-container').addClass("driveFormMselect");
          $('.select2-container').addClass("vcEmployeeSelect");
          $("body").append('<div class="select2backdrop ' + props.MultiCustomersId + '"></div>');
          $('.select2backdrop').on('click', function () {
            $(Control.ControlID).select2("close");
            if (isandroidiospro) {
              $(Control.ControlID).select2('destroy')
            }
          });
          if (!$("#div-select2-results-count").is(":visible")) {
            $('.select2-results').append('<div id="div-select2-results-count" class="select2-results-count"></div>');
          }
        });
      $(Control.ControlID).on('select2:close', function (e) {
        $('.select2backdrop ').hide().remove();
        $('.select2-container').removeClass("driveFormMselect");
      });
      $(Control.ControlID).on('select2:closing', function (e) {
        $(".select2-dropdown--below").removeClass("width100");
        $(".select2-results").css("visibility", "hidden");
      });
      $(Control.ControlID).on('select2:opening', function (e) {
        setTimeout(function () {
          $(".select2-dropdown--below").addClass("width100");
          $(".select2-results").css("visibility", "visible");
        }, 5);
      });
      $(Control.ControlID).on("change", function () {
        // OnChangeCustomer(customerDetails.filter((cust) => cust.userId === this.value).length > 0 ? customerDetails.filter((cust) => cust.userId === this.value)[0] : this.value);    
        let data = {}
        for (let index = 0; index < this.length; index++) {
          if (this.value == this[index].dataset.value) {
            data.dataset = this[index].dataset;
          }
        }
        var selectedValues = customerDetails.filter((cust) => cust.userId === this.value).length > 0 ? customerDetails.filter((cust) => cust.userId === this.value)[0] : this.value;
        setControlName("");
        OnChangeCustomer(this.value, data, selectedValues);
      });
      setTimeout(() => {
        $(Control.ControlID).trigger("select2:open");
        if (isandroidiospro) {
          $(Control.ControlID) && $(Control.ControlID).select2("open");
        } else {
          if (!firstLoad.current) {
            $(Control.ControlID) && $(Control.ControlID).select2("open");
            firstLoad.current = true;
          }
        }
      }, 500);
      setControlName(props.ControlID);
    }
  })

  useEffect(() => {
    ApplySinglSelect();
  }, [props.expandMinimizeShow])


  function connectWindowSize() {    
    if ((!isTablet && !isIpad && !isIpadold && navigator.userAgent.toLowerCase().indexOf("com.vagaro.androidpro") === -1 && navigator.userAgent.indexOf("Android") === -1)) {
      ApplySinglSelect();
    }
  }
  window.onresize = connectWindowSize;

  window.OnResizeSelectCustomer = () => {
    setTimeout(() => {
      connectWindowSize()
    }, 400);
  }


  useEffect(() => {
    setTimeout(() => {
      if (isandroidiospro) {
        $(Control.ControlID).select2("open");
      }
    }, 500);
    AddCommonMethod("OpenSelect2", () => $(Control.ControlID).select2("open"));
    AddCommonMethod("CloseSelect2", () => $(Control.ControlID).select2("close"));

  }, [])

  const CancelOnCLick = () => {
    $(".vg_clear_search").click();
    $('.select2-search--dropdown').removeClass("selectSearchActive");
  }

  return (
    <Fragment>
      <div>
        <fieldset>
          <select
            id={props.ControlID}
            className="expandedWidth vgselect-dropdown prosale-select2"
            disabled={props.disabled}
          ></select>
        </fieldset>
      </div>
    </Fragment>
  )
};

export default MerchantsSingleSelecttwo;