
import $ from 'jquery'; 
import React, { Fragment, useEffect, useState } from 'react';
import { unmountComponentAtNode, createPortal } from "react-dom";
import { CloseDialog, OpenDialog_Type, utils } from '../../utils/Utils';
import { MobileCallBackAction } from '../../Vagaroconnect/Binding/ChatBoatEnums';
import VGPopUp from '../../component/Common/VGPopUp';
import { Dropdown } from 'react-bootstrap';
import Select from 'react-select';

const ChooseBookngLinkComman = (props) => {
    const {bookingWithUs, EditorObject, setEditorData, title} = props
    const PopupTitle = () => {
            return <Fragment>{title}</Fragment>;
    };
    const [chooseBussiness, setChooseBussiness] = useState('1');
    const [isOpen, setIsOpen] = useState(false);
    const [option, setOption] = useState(null);
    
    const handleRadioChange = (event) => {
        setChooseBussiness(event.target.value);
    };
    useEffect(() => {
        const elements = document.querySelectorAll('.modal-dialog-scrollable');
        elements.forEach((element) => {
          element.classList.remove('modal-dialog-scrollable');
        });
      }, []);
    const toggleOpen = () => setIsOpen(prevState => !prevState);
    const heightPx = "30px";
    const zIndexBlanket = 1;
    const zIndexMenu = 2;

    // const customFilterOption = (option, inputValue) => {
    //    console.log(option, 'option===>')
    //    console.log(inputValue, 'inputValue')
    // };

    const selectStyles = {
        menu: provided => ({
            ...provided,
            position: "relative",
            boxShadow: "none",
            margin: 0,
        }),
        input: provided => ({
            ...provided,
            width: "100%",
            cursor: "text",
        }),
        placeholder: provided => ({
            ...provided,
            cursor: "text",
        }),
        menuList: provided => ({
            ...provided,
            padding: 0,
        }),
        option: provided => ({
            ...provided,
            height: heightPx,
        }),
    };
    const onSelectChange = selectedOption => {
        toggleOpen();
        setOption(selectedOption);
    }
    const options = [
        {
            label: "USA",
            options: [
                { value: "New York", label: "New York" },
                { value: "Los Angeles", label: "Los Angeles" },
                { value: "Chicago", label: "Chicago" },
            ],
        },
        {
            label: "India",
            options: [
                { value: "Mumbai", label: "Mumbai" },
                { value: "Delhi", label: "Delhi" },
                { value: "Bangalore", label: "Bangalore" },
            ],
        },
        {
            label: "Canada",
            options: [
                { value: "Toronto", label: "Toronto" },
                { value: "Vancouver", label: "Vancouver" },
                { value: "Montreal", label: "Montreal" },
            ],
        },
    ];
    const CustomIcon = () => (        
        <i className="fa-solid fa-search"></i>        
      );
 
    // const MainButton = styled.button`
    // border: none;
    // margin: 0;
    // padding: 10px;
    // text-decoration: none;
    // cursor: pointer;
    // width: 100%;
    // `;

    // const Menu = styled.div`
    // position: relative;
    // left: 0;
    // right: 0;
    // background-color: white;
    // border-radius: 4px;
    // z-index: ${zIndexMenu};
    // `;

    // const Blanket = styled.div`
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // top: 0;
    // right: 0;
    // z-index: ${zIndexBlanket};
    // `;

    // const StyledDropdown = styled.div`
    // position: relative;
    // width: 240px;
    // `;

    const Svg = p => (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            focusable="false"
            role="presentation"
            {...p}
        />
    );

    // const StyledIndicator = styled.div`
    // color: gray;
    // height: 24;
    // width: 32;
    // display: ${props => (props.hidden ? "inline-block" : "none")};
    // `;

    const ChevronDown = () => (
        <i class="fa-solid fa-caret-down"></i>
    );

    const Xicon = () => (
        <svg height="20" width="20" viewBox="0 0 20 20">
            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
    );

    const Dropdown = ({ children, isOpen, target, onClose }) => (
        <div className='multiLocationSelectBlockWrap'>
            {target}
            {isOpen ? <div id="menu" className='multiLocationSelectWrap'>{children}</div> : null}
            {isOpen ? <div id="blanket" onClick={onClose} /> : null}
        </div>
    );
    const handleAddBookingLink = () => {
        if (chooseBussiness === "1") {
            const editor = EditorObject.current;
            let linkHtml = "";
            if (bookingWithUs.urlObject === "Book With Us 24/7") {
                linkHtml = bookingWithUs.url;
            }
            else {
                linkHtml = `<p><a target="_blank" href="${bookingWithUs.url}">${bookingWithUs.url}</a> </p>`;
            }

            const viewFragment = editor.data.processor.toView(linkHtml);
            const modelFragment = editor.data.toModel(viewFragment);
            editor.model.insertContent(modelFragment);
            // if (!isAndroidIOSTabIpad()) {
            //     EditorObject.current.focus();
            // }
            const updatedEditorData = editor.getData() + linkHtml;
            setEditorData(updatedEditorData);
            handlepopupClose()
        }
    }
    const PopupBody = () => {

            return (
                <Fragment>
                    <div className='bookingLinkWrap'>
                        <div className="bookingLinkBlock allLocation">
                            <div className="customredio-box-singal">
                                <input
                                    type="radio"
                                    name="bookingOption"
                                    id="demo1"
                                    value={1}
                                    checked={chooseBussiness == 1}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="demo1">
                                <span className='radiocircle'></span>
                                <span className='radiocircle radiocircle-checked'>
                                    <i class="fa-solid fa-check"></i>
                                </span>
                                    <div className="ai-boxt-heading">All Locations</div>
                                    <div className="ai-boxt-description">
                                        Customers will be prompted to first choose a location they would like to book at.
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="bookingLinkBlock singleLocation">
                            <div className="customredio-box-singal">
                                <input
                                    type="radio"
                                    name="bookingOption"
                                    id="demo2"
                                    value={2}
                                    checked={chooseBussiness == 2}
                                    onChange={handleRadioChange}
                                />
                                <label htmlFor="demo2">
                                <span className='radiocircle'></span>
                                <span className='radiocircle radiocircle-checked'>
                                    <i class="fa-solid fa-check"></i>
                                </span>
                                    <div className="ai-boxt-heading">Single Location</div>
                                    <div className="ai-boxt-description">
                                    Choose a specific location’s booking link for customers to book at.
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>

                    {chooseBussiness == 2 && (
                        <div className="textbox-wrapper multiLocationSelectMainWrap">
                            <label htmlFor="singleLocationInput" className="textbox-label">
                                Choose Business
                                <i class="fa-solid fa-chevron-right"></i>
                            </label>
                            <Dropdown
                                isOpen={isOpen}
                                onClose={toggleOpen}
                                target={
                                    <div className='multiLocationSelectBlock' id="button" onClick={toggleOpen} isSelected={isOpen}>
                                        {option ? option.label : "Select"}
                                        <ChevronDown />
                                    </div>
                                }
                            >
                                <Select
                                    autoFocus
                                    components={{
                                        DropdownIndicator: CustomIcon,
                                        // DropdownIndicator,
                                        IndicatorSeparator: null,
                                        // Option: CustomOption,
                                    }}
                                    isClearable={false}
                                    menuIsOpen
                                    controlShouldRenderValue={false}
                                    onChange={onSelectChange}
                                    options={options}
                                    placeholder="Search..."
                                    styles={selectStyles}
                                    tabSelectsValue={false}
                                    value={option}
                                    classNamePrefix="multiLocationSelect"
                                    noOptionsMessage={() => `No results found`}
                                    // isLoading={true}
                                    // filterOption={customFilterOption}
                                />
                            </Dropdown>

                        </div>
                    )}
                </Fragment>
            )
    }

    const Footer = () => {
            return (
                <Fragment>
                    <button className="btn btn-secondary vcgradientbtn vcsecondary-btn upgradeText-cancelbtn" onClick={() => handlepopupClose()}>
                        <div className='vcsecondaryInner'>
                            Cancel
                        </div>
                    </button>
                    <button
                    className="btn vcconnect-primary-btn vcgradientbtn vcprimary-btn"
                    onClick={() => {
                        console.log("Add booking link clicked");
                        handleAddBookingLink();
                    }}
                >
                    Add Booking Link
                </button>
                </Fragment >
            )
    }
    const handlepopupClose = () => {
        if(props.isRestricted){
            $("body").removeClass("ris-popup");
        }
        // unmountComponentAtNode(document.getElementById("OpenDialog1"));
        CloseDialog(OpenDialog_Type.OpenDialog1)
    };

    const popUpShow = () => {
        setTimeout(() => {
            return true
        }, 100);
    }

    return createPortal(
        <VGPopUp
            show={popUpShow}
            title={PopupTitle()}
            onHide={handlepopupClose}
            body={PopupBody()}
            footer={Footer()}
            dialogClassName={"vgn-modal-alert tieredPricingModal bookingLinkTypeModal"}
            isShowFooter={true}
            isStatic={props.isFromConnectApp && true}
        />,
        document.getElementById("OpenDialog1")
    )
}

export default ChooseBookngLinkComman;